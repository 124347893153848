<template>
  <div class="devices">
    <panes>
      <template v-slot:left>
        <div class="device-list">
          <DeviceTable
            v-on:rowClick="handleRowClick"
            :events="deviceEvent"
            :unapproved="false"
            :reload="reload"
            title="Approved devices"
          />
        </div>
      </template>
      <template v-slot:right>
        <div class="device-view">
          <EditDevice
            v-if="['device-edit'].indexOf($route.name) > -1"
            :device-id="$route.params.device"
            v-on:edit="editHandler"
          />
          <DeleteDevice
            v-else-if="['device-delete'].indexOf($route.name) > -1"
            :device-id="$route.params.device"
            v-on:edit="editHandler"
            v-on:blur="blurHandler"
          />
          <GraphDevice
            v-else-if="['device-graph'].indexOf($route.name) > -1"
            :device-id="$route.params.device"
            v-on:edit="editHandler"
            v-on:blur="blurHandler"
          />
          <SingleDevice
            v-else-if="selected !== null && typeof selected === 'string'"
            :device-id="selected"
            :events="deviceEvent"
            v-on:vpnAction="vpnActionHandler"
            v-on:noMachine="noMachineHandler"
            v-on:rebootDevice="rebootDeviceHandler"
            v-on:restartWherever="restartWhereverHandler"
            v-on:updateWherever="updateWhereverHandler"
            v-on:updateYoYo="updateYoYoHandler"
            v-on:downloadMagicPlayerPackage="downloadMagicPlayerPackage"
            v-on:resticBackup="resticBackup"
            v-on:sslCertificateDeploy="sslCertificatesDeployment"
            v-on:sslCertificateStatus="sslCertificatesStatus"
            v-on:logZip="logZipRequestHandler"
          />
          <MultiOverview
            v-else-if="selected !== null && selected.length !== undefined"
            :device-ids="selected"
            v-on:clear="handleClearSelection()"
          />
        </div>
      </template>
    </panes>
  </div>
</template>

<script>
import DeviceTable from '../../components/Table/Device.vue';
import SingleDevice from '../../components/Device/Read.vue';
import MultiOverview from '../../components/Device/MultiOverview.vue';
import EditDevice from '../../components/Device/Edit.vue';
import DeleteDevice from '../../components/Device/Delete.vue';
import GraphDevice from '../../components/Device/Graph.vue';
import Panes from '../../components/Panes/Panes.vue';

export default {
  name: 'devices',
  components: {
    SingleDevice,
    DeviceTable,
    EditDevice,
    DeleteDevice,
    GraphDevice,
    MultiOverview,
    Panes,
  },
  data() {
    return {
      selected: null,
      webSocket: null,
      deviceEvent: null,
      reload: null,
      websocketKeepAlive: null,
    };
  },
  methods: {
    startWebSocket() {
      let protocol = 'ws';
      if (window.location.protocol.substring(4, 5) === 's') {
        protocol += 's';
      }

      const jwt = window.localStorage.getItem('jwt');
      const date = new Date(Date.now() + 60000 * 60 * 24).toUTCString();
      document.cookie = `X-Authorization=${jwt}; expires=${date}; path=/api/ws`;
      this.webSocket = new WebSocket(`${protocol}://${window.location.host}/api/ws`);

      this.webSocket.onopen = () => {
        // eslint-disable-next-line no-console
        console.log('Opened the websocket connection with the server.');
        this.websocketKeepAlive = setInterval(() => {
          if (this.webSocket) {
            this.webSocket.send('ping');
          } else {
            clearInterval(this.websocketKeepAlive);
          }
        }, 15000);
      };
      this.webSocket.onclose = () => {
        // eslint-disable-next-line no-console
        console.log('Closed the websocket connection with the server.');
        clearInterval(this.websocketKeepAlive);
      };
      this.webSocket.onmessage = (event) => {
        try {
          const obj = JSON.parse(event.data);
          if (obj.type === 'deviceChange') {
            this.deviceEvent = {
              type: 'device',
              online: obj.online,
              target: obj.target,
              vpn: typeof obj.vpn !== 'undefined' ? obj.vpn.connected : false,
            };
          } else if (obj.type === 'enableVpn') {
            this.deviceEvent = {
              type: 'vpn',
              success: obj.success,
              target: obj.device,
              ip: obj.ip,
              turnedOn: true,
            };
          } else if (obj.type === 'disableVpn') {
            this.deviceEvent = {
              type: 'vpn',
              success: obj.success,
              target: obj.device,
              turnedOn: false,
            };
          } else if (obj.type === 'noMachineStatus') {
            this.deviceEvent = {
              type: 'noMachineStatus',
              success: obj.success,
              active: obj.active,
              target: obj.device,
              message: obj.message,
              port: obj.port,
            };
          } else if (obj.type === 'magicPlayerResponse') {
            this.deviceEvent = {
              type: 'magicPlayerResponse',
              downloading: obj.downloading,
              success: obj.success,
              message: obj.message,
              device: obj.device,
            };
          } else if (obj.type === 'magicPlayerStatus') {
            this.deviceEvent = {
              type: 'magicPlayerStatus',
              downloading: obj.downloading,
              extracting: obj.extracting,
              completed: obj.completed,
              message: obj.message,
              device: obj.device,
            };
          } else if (obj.type === 'magicPlayerMessage') {
            this.$root.$emit('magicPlayerMessage', {
              logLevel: obj.logLevel,
              message: obj.message,
              device: obj.device,
            });
          } else if (obj.type === 'resticResponse') {
            this.$root.$emit('resticResponse', obj);
          } else if (obj.type === 'sslCertificate') {
            this.$root.$emit('sslCertificate', obj);
          } else if (obj.type === 'logZipStatus') {
            this.handleLogZipResponse(obj);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      };
    },
    editHandler() {
      this.reload = Date.now();
    },
    handleClearSelection() {
      this.deviceEvent = {
        type: 'clearSelection',
      };
    },
    blurHandler() {
      this.selected = null;
    },
    vpnActionHandler(action) {
      if (this.webSocket !== null) {
        this.webSocket.send(
          JSON.stringify({
            type: action.on ? 'enableVpn' : 'disableVpn',
            target: action.target,
            jwt: localStorage.getItem('jwt'),
          }),
        );
      }
    },
    noMachineHandler(action) {
      this.webSocket.send(
        JSON.stringify({
          type: action.on ? 'noMachineStart' : 'noMachineStop',
          target: action.target,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    rebootDeviceHandler(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'reboot',
          target: action.target,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    logZipRequestHandler(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'logZip',
          target: action.target,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    handleLogZipResponse(message) {
      if (message.success) {
        this.$noty.success('The zip will be downloaded shortly...');
        this.$root.$emit('downloadLogZip', {
          downloadLocation: `https://cdn.wherever.network/logs/${message.flavor}/${message.device}/log.zip`,
          device: message.device,
        });
      } else {
        this.$noty.warning(message.message);
      }
    },
    restartWhereverHandler(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'restart',
          target: action.target,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    updateWhereverHandler(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'update',
          target: action.target,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    downloadMagicPlayerPackage(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'magicplayerDownload',
          target: action.target,
          downloadAction: action.downloadAction,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    sslCertificatesDeployment(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'sslCertificateDeployment',
          target: action.target,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    sslCertificatesStatus(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'sslCertificateStatus',
          target: action.target,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    updateYoYoHandler(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'yoyo',
          target: action.target,
          stable: action.stable,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    stopWebSocket() {
      if (this.webSocket !== null) {
        this.webSocket.close();
        this.webSocket = null;
      }
    },
    resticBackup(action) {
      this.webSocket.send(
        JSON.stringify({
          type: 'resticBackup',
          target: action.target,
          jwt: localStorage.getItem('jwt'),
        }),
      );
    },
    handleRowClick(id) {
      this.selected = id;
      if (typeof id === 'string') {
        this.$router.replace(`/devices/approved/${id}`).catch(() => {
          // Continue regardless of error
        });
      }
    },
  },
  async mounted() {
    this.startWebSocket();
    if (typeof this.$route.params.device !== 'undefined') {
      this.selected = this.$route.params.device;
    }
  },

  beforeDestroy() {
    this.stopWebSocket();
  },
};
</script>

<style lang="scss" scoped>
.devices {
  height: 100%;
  box-sizing: border-box;
}
.device-list {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.device-view {
  height: 100%;
  overflow-x: auto;
  box-sizing: border-box;
}
</style>
