import Vue from 'vue';
import Router, { RawLocation } from 'vue-router';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { ErrorHandler, Route } from 'vue-router/types/router';
import Dashboard from './views/Dashboard.vue';
import Devices from './views/Device/Approved.vue';
import Login from './views/Login.vue';
import Logout from './views/Logout.vue';

const originalPush = Router.prototype.push;
const pushReplacement = function push(
  this: any,
  location: RawLocation,
  onComplete?: Function,
  onAbort?: ErrorHandler,
): void | Promise<Route> {
  if (onComplete || onAbort) {
    return originalPush.call(this, location, onComplete, onAbort);
  }
  return ((originalPush.call(this, location) as unknown) as Promise<Route>).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      return err.to;
    }

    throw err;
  });
};
Router.prototype.push = (pushReplacement as unknown) as {
  (location: RawLocation): Promise<Route>;
  (
    location: RawLocation,
    onComplete?: Function | undefined,
    onAbort?: ErrorHandler | undefined,
  ): void;
};

Router.prototype.replace = (pushReplacement as unknown) as {
  (location: RawLocation): Promise<Route>;
  (
    location: RawLocation,
    onComplete?: Function | undefined,
    onAbort?: ErrorHandler | undefined,
  ): void;
};

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      path: '/devices',
      redirect: '/devices/approved',
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        title: 'Wherever | Login',
        guest: true,
      },
      component: Login,
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        requiresAuth: false,
      },
      component: Logout,
    },
    {
      path: '/devices/approved/:device?',
      name: 'devices',
      meta: {
        title: 'Wherever | Approved Devices',
        requiresAuth: true,
      },
      component: Devices,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: {
        title: 'Wherever | Dashboard',
        requiresAuth: true,
      },
      component: Dashboard,
    },
    {
      path: '/devices/edit/:device',
      name: 'device-edit',
      meta: {
        title: 'Wherever | Edit Device',
        requiresAuth: true,
      },
      component: Devices,
    },
    {
      path: '/devices/graph/:device',
      name: 'device-graph',
      meta: {
        title: 'Wherever | Device graphs',
        requiresAuth: true,
      },
      component: Devices,
    },
    {
      path: '/devices/delete/:device',
      name: 'device-delete',
      meta: {
        title: 'Wherever | Delete Device',
        requiresAuth: true,
      },
      component: Devices,
    },
    {
      path: '/organizations',
      name: 'organization',
      meta: {
        title: 'Wherever | Organizations',
        requiresAuth: true,
      },
      component: () => import('./views/Organization.vue'),
    },
    {
      path: '/organizations/:organization',
      name: 'organizationParam',
      meta: {
        title: 'Wherever | Organizations',
        requiresAuth: true,
      },
      component: () => import('./views/Organization.vue'),
    },
    {
      path: '/organizations/:organization/user/:user?',
      name: 'organizationUser',
      meta: {
        title: 'Wherever | Organizations',
        requiresAuth: true,
      },
      component: () => import('./views/Organization.vue'),
    },
    {
      path: '/organizations/:organization/token/:token?',
      name: 'organizationToken',
      meta: {
        title: 'Wherever | Organizations',
        requiresAuth: true,
      },
      component: () => import('./views/Organization.vue'),
    },
    {
      path: '/miservers',
      name: 'miservers',
      meta: {
        title: 'Wherever | MI Servers',
        requiresAuth: true,
      },
      component: () => import('./views/Miserver.vue'),
    },
    {
      path: '/miservers/:miserver',
      name: 'miserverParam',
      meta: {
        title: 'Wherever | MI Server',
        requiresAuth: true,
      },
      component: () => import('./views/Miserver.vue'),
    },
    {
      path: '/miservers/:miserver/edit',
      name: 'miserverEdit',
      meta: {
        title: 'Wherever | MI Server',
        requiresAuth: true,
      },
      component: () => import('./views/Miserver.vue'),
    },
    {
      path: '/magicinfo-player-content',
      name: 'magicinfo-player-content',
      meta: {
        title: 'Wherever | MI Player Content',
        requiresAuth: true,
      },
      component: () => import('./views/MiPlayerContent.vue'),
    },
    {
      path: '/magicinfo-player-content/view/:groupUnique',
      name: 'magicinfo-player-content-view',
      meta: {
        title: 'Wherever | MI Player Content',
        requiresAuth: true,
      },
      component: () => import('./views/MiPlayerContent.vue'),
    },
    {
      path: '/magicinfo-player-content/new',
      name: 'magicinfo-player-content-new',
      meta: {
        title: 'Wherever | MI Player Content',
        requiresAuth: true,
      },
      component: () => import('./views/MiPlayerContent.vue'),
    },
    {
      path: '/alerts',
      name: 'alerts',
      meta: {
        title: 'Wherever | Alerts',
        requiresAuth: true,
      },
      component: () => import('./views/Alert.vue'),
    },
    {
      path: '/alerts/manager',
      name: 'alertManager',
      meta: {
        title: 'Wherever | Alert Manager',
        requiresAuth: true,
      },
      component: () => import('./views/AlertManager.vue'),
    },
    {
      path: '/alerts/manager/:alert',
      name: 'alertParam',
      meta: {
        title: 'Wherever | View Alerts',
        requiresAuth: true,
      },
      component: () => import('./views/AlertManager.vue'),
    },
    {
      path: '/alerts/manager/:alert/edit',
      name: 'alertEdit',
      meta: {
        title: 'Wherever | Edit Alert',
        requiresAuth: true,
      },
      component: () => import('./views/AlertManager.vue'),
    },
    {
      path: '/alerts/manager/create',
      name: 'alertCreate',
      meta: {
        title: 'Wherever | Create Alert',
        requiresAuth: true,
      },
      component: () => import('./views/AlertManager.vue'),
    },
    {
      path: '/devices/unapproved',
      name: 'Unapproved',
      meta: {
        title: 'Wherever | Unapproved Devices',
        requiresAuth: true,
      },
      component: () => import('./views/Device/Unapproved.vue'),
    },
    {
      path: '/settings',
      name: 'Settings',
      meta: {
        title: 'Wherever | Settings',
        requiresAuth: true,
      },
      component: () => import('./views/Settings.vue'),
    },
    {
      path: '/restic',
      name: 'Restic',
      meta: {
        title: 'Wherever | Restic',
        requiresAuth: true,
      },
      component: () => import('./views/Restic.vue'),
    },
    {
      path: '/restic/create',
      name: 'resticCreate',
      meta: {
        title: 'Wherever | Create Restic',
        requiresAuth: true,
        create: true,
      },
      component: () => import('./views/Restic.vue'),
    },
    {
      path: '/restic/:restic',
      name: 'resticView',
      meta: {
        title: 'Wherever | View Restic',
        requiresAuth: true,
      },
      component: () => import('./views/Restic.vue'),
    },
    {
      path: '/restic/:restic/edit',
      name: 'resticEdit',
      meta: {
        title: 'Wherever | Edit Restic',
        requiresAuth: true,
      },
      component: () => import('./views/Restic.vue'),
    },
    {
      path: '/restic/:restic/snapshots',
      name: 'resticSnaps',
      meta: {
        title: 'Wherever | Restic Snapshots',
        requiresAuth: true,
      },
      component: () => import('./views/Restic.vue'),
    },
    {
      path: '/certificate',
      name: 'Certificates',
      meta: {
        title: 'Wherever | Certificate',
        requiresAuth: true,
      },
      component: () => import('./views/Certificate.vue'),
    },
    {
      path: '/certificate/create',
      name: 'certificateCreate',
      meta: {
        title: 'Wherever | Create Certificate',
        requiresAuth: true,
        create: true,
      },
      component: () => import('./views/Certificate.vue'),
    },
    {
      path: '/certificate/:certificate/edit',
      name: 'certificateEdit',
      meta: {
        title: 'Wherever | Edit Certificate',
        requiresAuth: true,
      },
      component: () => import('./views/Certificate.vue'),
    },
    {
      path: '/certificate/:certificate/deploy',
      name: 'certificateDeploy',
      meta: {
        title: 'Wherever | Deploy Certificate',
        requiresAuth: true,
      },
      component: () => import('./views/Certificate.vue'),
    },
    {
      path: '/auth/2fa',
      name: '2FA',
      meta: {
        title: 'Wherever | 2FA',
        guest: true,
      },
      component: () => import('./views/TWOFA.vue'),
    },
  ],
});
