<template>
  <div class="device-viewer" v-if="device !== null">
    <h1 class="title">{{ device.name }}</h1>
    <h4 class="sub-name">{{ device.hwUnique }}</h4>
    <grid>
      <grid-col size="6">
        <card title="Device Information">
          <TextProperty title="Organization" :value="device.organization.name" />
          <TextProperty
            title="First Seen"
            :value="$date(new Date(parseInt(device._id.substring(0, 8), 16) * 1000)).fromNow()"
          />
          <TextProperty title="UUID" :value="device.hwUnique" />
          <TextProperty title="External IP" :value="device.system.externalIp" />
          <TextProperty title="Internal IP" :value="device.system.internalIp" />
          <TextProperty title="Mac Address" :value="device.system.macAddress" />
        </card>
      </grid-col>
      <grid-col size="6">
        <card title="Information to delete">
          <p>VPN client : {{ deleteItems.vpn }}</p>
          <p>Commands : {{ deleteItems.commands }}</p>
          <p>Device : 1</p>
          <div v-if="isAdmin()" class="property">
            <base-button
              type="DELETE"
              iconType="TRASH"
              v-on:click="deleteDevice(device._id)"
              title="Delete"
            />
            <base-button type="THEMED" iconType="CANCEL" v-on:click="cancel()" title="Cancel" />
          </div>
        </card>
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import BaseComponent from '../Base.vue';
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'deleteDevice',
  extends: BaseComponent,
  components: {
    TextProperty,
    Card,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['deviceId'],
  watch: {
    deviceId(newVal) {
      this.getDevice(newVal);
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      device: null,
      name: '',
      location: {
        latitude: '',
        longitude: '',
      },
      lp: null,
      deleteItems: {},
    };
  },
  methods: {
    async getDevice(id) {
      const res = await Utils.fetch(`/api/v1/devices/${id}`, {}, this).then((_res) => _res.json());
      if (res.success) {
        this.ajaxCompleted = true;
        this.device = res.device;
        this.name = res.device.name;

        if (typeof res.device.location !== 'undefined') {
          this.location = res.device.location;
        }
      } else {
        this.$noty.warning(res.message);
      }
    },
    async getDryDelete(id) {
      this.deleteItems = {};
      const res = await Utils.fetch(`/api/v1/devices/${id}/delete`, {}, this).then((_res) =>
        _res.json(),
      );
      if (res.success) {
        this.deleteItems = {
          commands: res.commands.length,
          vpn: res.vpnClients.length,
          device: res.device,
        };
      } else {
        this.$noty.warning(res.message);
      }
    },
    async deleteDevice(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you really sure you want to delete this device?')) {
        const res = await Utils.fetch(
          `/api/v1/devices/${id}/delete`,
          {
            method: 'POST',
          },
          this,
        )
          .then((_res) => _res.json())
          .catch((error) => {
            console.error(error.response);
          });
        if (res.success) {
          this.$emit('blur');
          this.$emit('edit');
          this.$router.push('/devices/approved');
        } else {
          this.$noty.warning(res.message);
        }
      }
    },
    cancel() {
      this.$router.push(`/devices/approved/${this.deviceId}`);
    },
  },
  mounted() {
    this.getDevice(this.deviceId);
    this.getDryDelete(this.deviceId);
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.property {
  margin: 15px 0;
}

.title-label {
  margin: 8px 0;
}

.tags-input {
  input {
    font-size: 13px;
  }
}

.sub-name {
  margin-top: -15px;
  margin-bottom: 30px;
}

.loader {
  text-align: center;
  padding: 20px 0;

  img {
    height: 30px;
  }
}

.switch {
  margin-top: 6px;
}

.link {
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  &.external {
    background-position: center right;
    background-repeat: no-repeat;
    background-image: linear-gradient(transparent, transparent),
      url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22%3E %3Cpath fill=%22%233a4149%22 stroke=%22%23fff%22 d=%22M1.5 4.518h5.982V10.5H1.5z%22/%3E %3Cpath fill=%22%233a4149%22 d=%22M5.765 1H11v5.39L9.427 7.937l-1.31-1.31L5.393 9.35l-2.69-2.688 2.81-2.808L4.2 2.544z%22/%3E %3Cpath fill=%22%23fff%22 d=%22M9.995 2.004l.022 4.885L8.2 5.07 5.32 7.95 4.09 6.723l2.882-2.88-1.85-1.852z%22/%3E %3C/svg%3E');
    padding-right: 13px;
  }
}

#map,
.location-picker {
  width: 100%;
  height: 200px;
}
</style>
