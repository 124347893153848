<template>
  <div>
    <div class="card" v-if="collapse">
      <span v-if="title">
        <h4 v-if="collapsed" @click="collapsed = !collapsed">
          {{ title }}
          <i class="uil uil-angle-down right"></i>
        </h4>
        <h4 v-else @click="collapsed = !collapsed">
          {{ title }}
          <i class="uil uil-angle-up right"></i>
        </h4>
      </span>
      <slot v-if="!collapsed" />
    </div>
    <div class="card" v-else>
      <h4 v-if="title" class="card-title">
        {{ title }}
      </h4>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: String,
    collapse: Boolean,
  },
  data() {
    return {
      collapsed: true,
    };
  },
};
</script>

<style scoped lang="scss">
h1,
h2,
h3,
h4 {
  margin-top: 0;
}

.card {
  background: var(--accent-color);
  padding: 16px;
  border: var(--card-border);
  box-shadow: var(--card-shadow);
  border-radius: 3px;
  margin-bottom: 18px;
  width: 100%;
  box-sizing: border-box;
}

h4 {
  color: var(--header-font-color);
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
}

.card-body {
  padding: 5px;
}
</style>
