<template>
  <div>
    <card title="MagicINFO Player">
      <TextProperty
        title="MagicINFO Player Is Running"
        v-if="device.stats && device.stats.magicplayer"
        :value="device.stats.magicplayer.isRunning ? 'Yes' : 'No'"
      />
      <TextProperty
        title="Device Name"
        v-if="device.stats && device.stats.magicplayer"
        :value="device.stats.magicplayer.device"
      />
      <TextProperty
        title="Device Serial"
        v-if="device.stats && device.stats.magicplayer"
        :value="device.stats.magicplayer.serial"
      />
      <TextProperty
        title="MagicINFO Server"
        v-if="device.stats && device.stats.magicplayer"
        :value="device.stats.magicplayer.magicinfoServer"
      />
      <TagProperty
        title="MagicINFO Tags"
        customStyle="background-color: #165353; color: #fff;"
        v-if="device.stats && device.stats.magicplayer && device.stats.magicplayer.tags"
        :value="parseTags(device.stats.magicplayer.tags)"
      />
    </card>
    <card title="MagicINFO Schedule">
      <TextProperty
        title="Version"
        v-if="device.stats && device.stats.magicplayer"
        :value="device.stats.magicplayer.scheduleVersion"
      />
      <TextProperty
        title="Name"
        v-if="device.stats && device.stats.magicplayer"
        :value="device.stats.magicplayer.scheduleName"
      />
      <TextProperty
        title="ID"
        v-if="device.stats && device.stats.magicplayer"
        :value="device.stats.magicplayer.scheduleId"
      />
      <TextProperty
        title="LFD Name"
        v-if="device.stats && device.stats.magicplayer"
        :value="device.stats.magicplayer.lfdName"
      />
      <TextProperty
        title="LFD Size"
        v-if="device.stats && device.stats.magicplayer"
        :value="bytesToSize(device.stats.magicplayer.lfdSize)"
      />
      <TextProperty
        title="LFD Content Size"
        v-if="
          device.stats &&
          device.stats.magicplayer &&
          device.stats.magicplayer.extractedSize &&
          device.stats.magicplayer.extractedSize !== 0
        "
        :value="bytesToSize(device.stats.magicplayer.extractedSize)"
      />
    </card>
    <card title="MagicINFO CDN Control" v-if="device.online">
      <div v-if="actionableTag" class="content-group">
        Configured MagicINFO Player content group:
        <span class="ti-tag example-tag">{{ actionableTag }}</span>
        <hr>
      </div>
      <div v-if="actionableTag"
        class="properties"
        v-show="!(magicPlayerEvent != null && magicPlayerEvent.type == 'magicPlayerStatus')"
      >
        <base-button
          isSmall
          type="themed-button"
          iconType="DOWNLOAD"
          v-on:click="downloadMagicPlayerPackage(1)"
          title="Check download"
        />
        <base-button
          isSmall
          iconType="DOWNLOAD"
          type="THEMED"
          v-on:click="downloadMagicPlayerPackage(2)"
          title="Force full download"
        />
      </div>
      <div v-else class="content-group">
        There is no MagicINFO Player content group configured for this display.
        Downloading new content is disabled.
      </div>
      <div class="device-out" v-if="magicPlayerEvent != null">
        <div v-show="magicPlayerEvent != null && magicPlayerEvent.type == 'magicPlayerResponse'">
          {{ magicPlayerEvent.message }}
        </div>
        <div v-show="magicPlayerEvent != null && magicPlayerEvent.type == 'magicPlayerStatus'">
          <div v-if="!magicPlayerEvent.completed">
            <div class="clearfix progress-text">
              <span v-if="action !== ''">{{ action }}</span>
              <span v-else>Waiting...</span>
              <div class="right">
                {{ progressText }}
              </div>
            </div>
            <div class="outer-progress">
              <div class="inner-progress" ref="progress" />
            </div>
            <div>
              <base-button
                type="THEMED"
                iconType="CANCEL"
                v-on:click="downloadMagicPlayerPackage(3)"
                title="Cancel download"
              />
            </div>
          </div>
          <div v-else>Completed CDN transfer!</div>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import TextProperty from '../Property/TextProperty.vue';
import TagProperty from '../Property/TagProperty.vue';
import Card from '../Card/Card.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'MagicInfoPlayerStats',
  props: ['device', 'magicPlayerEvent'],
  components: {
    TextProperty,
    TagProperty,
    Card,
    BaseButton,
  },
  data() {
    return {
      action: '',
      progressText: '',
      actionableTag: '',
    };
  },
  watch: {
    device(newDevice) {
      this.action = '';
      this.progressText = '';

      const actionableTags = newDevice.tags.filter((t) => t.startsWith('Content Group: '));
      if (actionableTags.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.actionableTag = actionableTags[0];
      }
    },
    magicPlayerEvent(val) {
      if (val.type === 'magicPlayerStatus' && val.device === this.device.hwUnique) {
        this.handleEventValue(val);
      }
    },
  },
  methods: {
    handleEventValue(val) {
      // Disabled prefer-destructuring, should be fixed in the future
      const percentage = val.message.match(/[0-9]{1,2}\.[0-9]{1,2}%/);
      const action = val.message.match(/^(.*)?\(/);
      if (percentage.length > 0) {
        if (this.$refs.progress) {
          // eslint-disable-next-line prefer-destructuring
          this.$refs.progress.style.width = percentage[0];
        }
        // eslint-disable-next-line prefer-destructuring
        this.progressText = percentage[0];
      }
      if (action.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        this.action = action[1];
      }
    },
    bytesToSize(bytes) {
      if (!Number.isNaN(bytes)) {
        // Disabled for now, shouldn't be needed anymore when Typescript is implement here
        // eslint-disable-next-line no-param-reassign
        bytes = parseInt(bytes, 10);
      } else {
        return '-';
      }

      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024), 10), 10);
      if (i !== 0) {
        return `${Math.round((bytes / 1000 ** i) * 100) / 100} ${sizes[i]}`;
      }
      return `${Math.round(bytes / 1000 ** i)} ${sizes[i]}`;
    },
    parseTags(tags) {
      if (tags.length) {
        return tags.map((key) => `${key}: ${tags[key]}`);
      }

      return Object.keys(tags).map((key) => `${key}: ${tags[key]}`);
    },
    downloadMagicPlayerPackage(type) {
      this.$emit('downloadMagicPlayerPackage', type);
    },
  },
  mounted() {
    if (this.$refs.progress) {
      this.$refs.progress.style.width = '0%';
    }

    const actionableTags = this.device.tags.filter((t) => t.startsWith('Content Group: '));
    if (actionableTags.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.actionableTag = actionableTags[0];
    }

    this.$root.$on('magicPlayerMessage', (messageObj) => {
      if (messageObj.device === this.device.hwUnique) {
        if (messageObj.logLevel === 'error') {
          this.$noty.error(messageObj.message);
        }
      }
    });
  },
};
</script>
<style scoped lang="scss">
.device-out {
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  padding: 10px;
}

.progress-text {
  font-size: 12px;
  opacity: 0.5;
}

.outer-progress {
  height: 10px;
  margin-top: 8px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 2px;

  .inner-progress {
    width: 0;
    background: #f8b36b;
    left: 0;
    height: 100%;
  }
}

.cancel-button {
  margin-top: 16px;
}
.example-tag {
  margin: 3px 0;
  font-size: 11px;
  white-space: nowrap;
  display: inline-block;
}
.content-group {
  padding: 6px 0;
}
</style>
