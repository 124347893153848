<template>
  <div class="device-viewer" v-if="device !== null">
    <h1 class="title" v-if="device.name !== null">{{ device.name }}</h1>
    <h1 class="title" v-else>Unnamed device</h1>
    <h4 class="sub-name">{{ device.hwUnique }}</h4>
    <card title="Device Information">
      <TextProperty title="Organization" :value="device.organization.name" />
      <TextProperty
        title="First Seen"
        :value="$date(new Date(parseInt(device._id.substring(0, 8), 16) * 1000)).fromNow()"
      />
      <TextProperty title="UUID" :value="device.hwUnique" />
      <TextProperty title="External IP" :value="device.system.externalIp" />
      <TextProperty title="Internal IP" :value="device.system.internalIp" />
      <TextProperty title="Mac Address" :value="device.system.macAddress" />
    </card>
    <card title="Device properties">
      <div class="properties">
        <div class="property">
          <div class="title-label">Name</div>
          <input type="text" placeholder="Name" class="input-text" v-model="name" />
          <span v-if="device.generator && device.generator['name'] !== undefined">
            <base-button
              :isSmall="true"
              type="THEMED"
              v-on:click="generate('name')"
              iconType="BACK"
            >
            </base-button>
            <base-button
              :isSmall="true"
              type="THEMED"
              v-on:click="regenerate('name')"
              iconType="FORWARD"
            >
            </base-button>
          </span>
        </div>
        <div class="extended-properties" v-if="device.fields">
          <div class="property" v-for="field in device.fields" :key="field.label">
            <div class="title-label">
              {{ field.label }}
            </div>
            <span v-if="field.type == 'text'">
              <input
                type="text"
                :placeholder="field.label"
                class="input-text"
                v-model="device.autofields[field.name]"
              />
            </span>
            <span v-if="field.type == 'select'">
              <select class="input-select" v-model="device.autofields[field.name]">
                <option :value="null" />
                <option v-for="option in field.values" :value="option.value" :key="option.label">
                  {{ option.label }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <hr />
        <div class="property">
          <div class="title-label">Tags</div>
          <vue-tags-input
            class="tags-input"
            v-model="tag"
            :tags="tags"
            :autocomplete-min-length="0"
            :autocomplete-items="filteredTags"
            :add-only-from-autocomplete="true"
            @tags-changed="(newTags) => (tags = newTags)"
          />
        </div>
      </div>
    </card>
    <card v-if="isAdmin() && device.name" :collapse="true" title="Device features">
      <div v-if="featurePresets && editFeatures">
        Choose preset:
        <span v-for="(preset, idx) in featurePresets" :key="preset._id">
          <a class="link" :title="preset.description" @click="enablePreset(preset.setting)">{{
            preset.name
          }}</a>
          <span v-if="idx < featurePresets.length - 1"> | </span>
        </span>
      </div>
      <grid>
        <grid-col size="6" v-if="editFeatures">
          <div v-for="(v, k) in featureSections" :key="k">
            <div class="title-label">
              <h4>{{ v }}</h4>
            </div>
            <div class="property" v-for="(field, key) in device.config[k]" :key="key">
              <b>{{ key }}</b>
              <div v-for="(val, fld) in device.config[k][key]" :key="fld">
                <Property>
                  <label v-if="typeof val === 'boolean'">
                    <input type="checkbox" v-model="device.config[k][key][fld]" /> {{ fld }}
                  </label>
                  <span v-if="typeof val === 'string'">
                    <input
                      type="text"
                      :placeholder="fld"
                      class="input-text"
                      v-model="device.config[k][key][fld]"
                    />
                  </span>
                </Property>
              </div>
            </div>
          </div>
        </grid-col>
        <grid-col size="6">
          <code style="white-space: pre">
            {{ device.config }}
          </code>
        </grid-col>
      </grid>
    </card>
    <!-- this card can not be loaded with collapse, since it relies on editGeoLocation -->
    <card>
      <h4 v-if="!editGeoLocation" @click="editGeoLocation = !editGeoLocation">
        Geographical location
        <i
          class="uil uil-globe"
          :class="location.latitude && location.longitude ? 'green' : 'orange'"
        ></i>
        <i class="uil uil-angle-down right"></i>
      </h4>
      <h4 v-else @click="editGeoLocation = !editGeoLocation">
        Geographical location
        <i
          class="uil uil-globe"
          :class="location.latitude && location.longitude ? 'green' : 'orange'"
        ></i>
        <i class="uil uil-angle-up right"></i>
      </h4>
      <div :style="editGeoLocation ? 'height:auto' : 'height: 0; overflow: hidden;'">
        <div class="property">
          <div class="form-group">
            <input id="pac-input" type="text" placeholder="Search for address" class="input-text" />
          </div>
          <gmap-map
            :center="savedCoordinates()"
            :options="mapOptions"
            ref="mymap"
            map-type-id="terrain"
            class="map"
          >
            <gmap-marker
              :position="savedCoordinates()"
              :clickable="true"
              :draggable="true"
              :icon="markerOptions"
              @dragend="updateCoordinates"
            />
          </gmap-map>
        </div>
        <div class="property">
          <div class="title-label">Latitude</div>
          <input
            id="latitude"
            type="text"
            placeholder="Lat"
            class="input-text"
            v-model="location.latitude"
          />
        </div>
        <div class="property">
          <div class="title-label">Longitude</div>
          <input
            id="longitude"
            type="text"
            placeholder="Long"
            class="input-text"
            v-model="location.longitude"
          />
        </div>
      </div>
    </card>
    <card>
      <div>
        <base-button
          iconType="CHECK"
          v-if="['device-edit'].indexOf($route.name) > -1"
          v-on:click="approveAndSave"
          title="Save"
        />
        <base-button v-else iconType="PLUS" v-on:click="approveAndSave" title="Approve and Save" />
        <base-button type="THEMED" title="Cancel" v-on:click="cancel()" />
        <base-button type="DELETE" title="Delete" v-on:click="remove()" class="right" />
      </div>
    </card>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
// TODO: We need to find a better solution for loading the flavor.
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable import/no-unresolved */
import VueTagsInput from '@johmun/vue-tags-input';
import Utils from '@/utils';
import Property from '@/components/Util/Property.vue';
import * as deepmerge from 'deepmerge';
import BaseComponent from '../Base.vue';
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';

export default {
  name: 'editDevice',
  extends: BaseComponent,
  components: {
    TextProperty,
    Property,
    VueTagsInput,
    Card,
    BaseButton,
    Grid,
    GridCol,
  },
  props: ['deviceId'],
  watch: {
    deviceId(newVal) {
      this.getDevice(newVal);
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      device: null,

      name: '',
      location: {
        latitude: '',
        longitude: '',
      },

      lp: null,

      tag: '',
      tags: [],
      autocompleteItems: [],
      mapOptions: {
        zoom: 14,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      markerOptions: {
        url: require('@/assets/yme-marker.png'),
      },
      editGeoLocation: true,

      editFeatures: false,
      featureSections: { app: 'App', remote: 'Remote' },
      // default features
      features: {
        app: {
          yoyo: {
            enabled: false,
            configFile: '',
          },
          myGarage: {
            enabled: false,
            configFile: '',
          },
          miServer: {
            enabled: false,
            databaseBackup: false,
            databaseBackupPath: '',
          },
          miPlayer: {
            enabled: false,
          },
          entraceManagement: {
            enabled: false,
          },
          resticClient: {
            enabled: false,
          },
          sslCertificate: {
            enabled: false,
          },
        },
        remote: {
          nomachine: {
            enabled: false,
          },
        },
      },
      featurePresets: [],
    };
  },
  methods: {
    /**
     * Generate a new value for a field as defined in organization.device.generator
     * @param field
     */
    generate(field) {
      const { generator } = this.device;
      if (typeof generator !== 'undefined') {
        switch (generator[field].type) {
          case 'join':
            this[field] = generator[field].fields
              .map((f) => this.device.autofields[f])
              .join(generator[field].join);
            break;
          default:
            break;
        }
      }
    },
    userCan(permission, resource) {
      return Utils.userCan(permission, resource);
    },
    regenerate(field) {
      const { generator } = this.device;
      if (generator) {
        if (generator[field].type === 'join') {
          const parts = this[field].split(generator[field].join);
          const { fields } = generator[field];
          parts.map((f, i) => this.$set(this.device.autofields, fields[i], f));
        }
      }
    },
    savedCoordinates() {
      return {
        lat: parseFloat(this.location.latitude),
        lng: parseFloat(this.location.longitude),
      };
    },
    enablePreset(setting) {
      this.device.config = deepmerge(this.device.config || {}, setting);
    },
    async getPresets() {
      const response = await Utils.fetch('/api/v1/devices/preset/feature', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.featurePresets = response.presets;
      }
    },
    async getDevice(id) {
      const response = await Utils.fetch(`/api/v1/devices/${id}`, {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.ajaxCompleted = true;
        this.device = response.device;
        this.name = response.device.name;
        if (response.device.tags) {
          this.tags = response.device.tags.map((e) => ({ text: e })) || [];
        }

        if (response.device.location) {
          this.location = response.device.location;
        }
      }
    },
    async getTags() {
      const response = await this.$utils.http().get('/api/v1/tags');
      this.autocompleteItems = response.data.map((e) => ({ text: e }));
    },
    async buildMap() {
      this.$gmapApiPromiseLazy().then(() => {
        if (this.$refs.mymap) {
          this.$refs.mymap.$mapPromise.then(() => {
            // eslint-disable-next-line no-undef
            initializeGmap(false, this.$refs.mymap.$mapObject);
            this.$nextTick(() => {
              this.editGeoLocation = false;
            });
          });
        }
      });
    },
    remove() {
      this.$router.push(`/devices/delete/${this.device._id}`);
    },
    updateCoordinates(location) {
      this.location.latitude = location.latLng.lat();
      this.location.longitude = location.latLng.lng();
    },
    async approveAndSave() {
      if (this.name && this.location.latitude && this.location.longitude) {
        const response = await Utils.fetch(
          `/api/v1/devices/${this.device._id}`,
          {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              approve: true,
              name: this.name,
              location: {
                latitude: this.location.latitude,
                longitude: this.location.longitude,
              },
              tags: this.tags.map((e) => e.text),
              autofields: this.device.autofields,
              features: this.device.config,
            }),
          },
          this,
        ).then((res) => res.json());

        if (response.success) {
          this.$noty.success('The settings were saved!');

          if (['device-edit'].indexOf(this.$route.name) > -1) {
            this.$emit('edit');
          }

          this.$nextTick(() => {
            this.$router.push(`/devices/approved/${this.device._id}`);
          });
        } else {
          this.$noty.warning(response.message);
        }
      } else {
        this.$noty.error('Please complete all fields.');
      }
    },
    cancel() {
      this.$router.push(`/devices/approved/${this.deviceId}`);
    },
    async loadFlavor() {
      this.flavor = (await fetch('/api/v1/flavor').then((res) => res.text())).trim();
      const url = `/${this.flavor}-marker.png`;
      if (url.indexOf('/-marker.png') === -1) {
        this.markerOptions.url = require(`@/assets/${this.flavor}-marker.png`);
      }
    },
  },
  mounted() {
    this.loadFlavor().then(() => {
      this.getTags();
      this.getDevice(this.deviceId)
        .then(() => this.buildMap())
        .then(() => {
          this.device.config = deepmerge(this.features, this.device.config || {});
        })
        .then(() => this.getPresets());
    });
  },
  computed: {
    filteredTags() {
      return this.autocompleteItems.filter(
        (i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1,
      );
    },
  },
};
</script>

<style scoped lang="scss">
.property {
  margin: 15px 0;
}

.editFeatures .property {
  margin: 5px 0;
}

.editFeatures h4 {
  margin: 10px 0 0 0;
}

.tags-input {
  input {
    font-size: 13px;
  }
}

.sub-name {
  margin-top: -15px;
  margin-bottom: 30px;
}

.loader {
  text-align: center;
  padding: 20px 0;

  img {
    height: 30px;
  }
}

.switch {
  margin-top: 6px;
}

.link {
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;

  &.external {
    background-position: center right;
    background-repeat: no-repeat;
    background-image: linear-gradient(transparent, transparent),
      url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22%3E %3Cpath fill=%22%233a4149%22 stroke=%22%23fff%22 d=%22M1.5 4.518h5.982V10.5H1.5z%22/%3E %3Cpath fill=%22%233a4149%22 d=%22M5.765 1H11v5.39L9.427 7.937l-1.31-1.31L5.393 9.35l-2.69-2.688 2.81-2.808L4.2 2.544z%22/%3E %3Cpath fill=%22%23fff%22 d=%22M9.995 2.004l.022 4.885L8.2 5.07 5.32 7.95 4.09 6.723l2.882-2.88-1.85-1.852z%22/%3E %3C/svg%3E');
    padding-right: 13px;
  }
}

.map {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}
</style>
