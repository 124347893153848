<template>
  <div class="device-viewer" v-if="device !== null">
    <h1 class="title" v-if="device.name !== null">
      {{ device.name }}
      <span
        v-if="typeof device.tags !== 'undefined'"
        v-html="
          device.tags
            .map((t) => {
              return '<span class=&quot;ti-tag&quot;>' + t + '</span>';
            })
            .join('')
        "
      />
    </h1>
    <h1 class="grey title" v-else @click="changeNameHandler">Unnamed device</h1>
    <h4 class="sub-name monospace">{{ device.hwUnique }}</h4>
    <AlertStatus :device="device._id" />
    <grid>
      <!-- Left column -->
      <grid-col size="6">
        <!-- General device information -->
        <card title="Device">
          <Property title="Status">
            <span v-if="device.online" class="green">Online</span>
            <span v-else class="red">Offline</span>
          </Property>
          <Property title="Online Since" v-if="device.online">
            {{ $date(new Date(device.onlineTime)).fromNow() }}
          </Property>
          <Property title="Last Seen" v-else-if="device.lastSeen !== undefined">
            {{ $date(new Date(device.lastSeen)).fromNow() }}
          </Property>
          <Property title="Organization">
            {{ device.organization.name }}
          </Property>
          <Property title="Approved Since">
            {{
              $date(new Date(device.approvedSince)).format('LLL') +
              ' (' +
              $date(new Date(device.approvedSince)).fromNow() +
              ')'
            }}
          </Property>
          <Property title="Last updated" v-if="device.stats">
            {{
              $date(new Date(device.stats.updatedAt)).format('LLL') +
              ' (' +
              $date(new Date(device.stats.updatedAt)).fromNow() +
              ')'
            }}
          </Property>
          <Property title="Wherever Client Version" v-if="device.clientVersion !== undefined">
            <span class="monospace">{{ device.clientVersion }}</span>
            <span v-if="device.stats && device.stats.buildNumber" class="buildnumber monospace">
              ({{ device.stats.buildNumber }})</span
            >
          </Property>
          <hr class="spacing" />
          <div class="clearfix">
            <router-link
              v-if="deviceSupportsApp('yoyo')"
              :to="{ name: 'device-graph', params: { device: device._id } }"
            >
              <base-button type="THEMED" title="Show statistics">
                <i class="uil uil-graph-bar" />
              </base-button>
            </router-link>
            <router-link :to="{ name: 'device-edit', params: { device: device._id } }">
              <base-button type="THEMED" iconType="EDIT" title="Edit" />
            </router-link>
            <router-link
              v-if="isAdmin()"
              :to="{ name: 'device-delete', params: { device: device._id } }"
            >
              <base-button type="DELETE" iconType="TRASH" title="Delete" />
            </router-link>
          </div>
        </card>

        <!-- Device stats -->
        <div>
          <Stats :device="device" />
        </div>

        <!-- MagicINFO Display actions -->
        <card
          v-if="device.online && userCan('edit', 'device') && device.system.os === 'magicinfo'"
          title="Display Actions"
        >
          <div class="properties">
            <ul>
              <li v-if="device.magicinfo.miserver && device.magicinfo.miserver.apiversion == '2.0'">
                <a class="link" @click="rebootDisplay">Reboot</a>
              </li>
              <li>
                <a class="link" @click="startRemoteManagement">Remote Management</a>
              </li>
              <li><a class="link" @click="manageTimers">Manage timers</a></li>
              <li>
                <a class="link" @click="setSourceMagicInfo">Set source to MagicINFO</a>
                (temp function)
              </li>
              <li>
                <a class="link" @click="setTimezoneMagicInfo">Set timezone to Amsterdam</a>
                (temp function)
              </li>
            </ul>
          </div>
        </card>

        <!-- Device actions -->
        <div v-if="device.online && userCan('edit', 'device') && device.system.os !== 'magicinfo'">
          <card title="Device Actions">
            <div class="properties">
              <ul>
                <li><a class="link" @click="rebootDevice">Reboot</a></li>
                <li>
                  <a class="link" @click="restartWherever">Restart Wherever Client</a>
                </li>
                <li v-if="isAdmin()">
                  <a class="link" @click="updateWherever">Update Wherever Client</a>
                </li>
                <li v-if="isAdmin()">
                  <a class="link" @click="handleLogZipRequest">Request Log Zip</a>
                </li>
              </ul>
            </div>
          </card>
        </div>

        <!-- Remote desktop -->
        <div
          v-if="
            device.online &&
            ((device.nomachine && ranNoMachineCommand) || deviceSupportsRemote('nomachine'))
          "
        >
          <NoMachineStatus :proxy="device.organization.proxy" />
          <card title="Remote Desktop Status">
            <div class="properties">
              <div class="property" v-if="userCan('edit', 'rdp')">
                <div class="title">Remote Desktop Toggle</div>
                <value-switch
                  @changed="handleNoMachine"
                  :enabled="noMachine.willOpen"
                  :isSuccess="noMachineIsOpen"
                />
              </div>
              <TextProperty title="NoMachine Status" :value="noMachine.status" />
              <TextProperty
                title="NoMachine Port"
                :value="device.nomachine.port.toString()"
                v-if="
                  noMachineIsOpen &&
                  device.nomachine.port !== undefined &&
                  device.nomachine.port !== ''
                "
              />
              <div v-if="noMachineIsOpen && userCan('edit', 'rdp')" class="nomachine-buttons">
                <a
                  style="text-decoration: underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  @click="
                    downloadNoMachineConfig(
                      location().origin + '/api/v1/nomachine/' + device.hwUnique + '.nxs',
                    )
                  "
                  >Download Connection</a
                >
                |
                <a :href="'nx://' + device.hwUnique + '.nxs'">Open Connection</a>
              </div>
            </div>
          </card>
        </div>

        <!-- VPN notice -->
        <div
          v-if="device.online && vpnIsOpen && device.vpn.ip !== undefined && device.vpn.ip !== ''"
        >
          <card class="notice">
            <h4>Notice</h4>
            <p style="margin-top: 10px">
              The VPN functionality is for debugging purposes only. You can enable it to access the
              device when you need it, but you have to disable it manually. Please close the VPN
              connection when you are done.
            </p>
          </card>
        </div>
        <!-- VPN -->
        <div v-if="device && !(
          device.config &&
          device.config.remote &&
          device.config.remote.vpn &&
          device.config.remote.vpn.enabled === false)">
          <!-- VPN status -->
          <div
            v-if="
              device.online &&
              device.vpn &&
              device.vpn.profile !== '' &&
              device.vpn.profile !== '-' &&
              device.system.os !== 'magicinfo'
            "
          >
            <card title="VPN Status">
              <div class="properties">
                <div class="property" v-if="userCan('edit', 'vpn')">
                  <div class="title">VPN Toggle</div>
                  <value-switch
                    @changed="handleVpn"
                    :enabled="vpn.vpnWillOpen"
                    :isSuccess="vpnIsOpen"
                  />
                </div>
                <TextProperty title="VPN Status" :value="vpn.vpnStatus" />
                <TextProperty
                  title="VPN IP Address"
                  :value="device.vpn.ip"
                  v-if="vpnIsOpen && device.vpn.ip !== undefined && device.vpn.ip !== ''"
                />
              </div>
            </card>
          </div>

          <!-- VPN actions -->
          <div
            v-if="
              device.online &&
              vpnIsOpen &&
              device.vpn.ip !== undefined &&
              device.vpn.ip !== '' &&
              (userCan('view', 'device') || userCan('edit', 'device'))
            "
          >
            <card title="VPN Action">
              <div class="properties">
                <ul>
                  <li v-if="device.organization.flavor === 'yme' && userCan('edit', 'device')">
                    <a class="link external" @click="openVNC">Open VNC</a>
                  </li>
                  <li v-if="device.organization.flavor === 'yoyo' && userCan('view', 'device')">
                    <a class="link external" @click="openYoYo">Open YoYo interface</a>
                  </li>
                  <li>
                    <a v-if="isAdmin()" class="link external" @click="openTerminal">
                      Open Terminal</a>
                  </li>
                </ul>
              </div>
            </card>
          </div>
        </div>
      </grid-col>

      <!-- Right column -->
      <grid-col size="6">
        <!-- System Information -->
        <div>
          <SystemInfo :device="device" />
        </div>

        <!-- Device notes -->
        <div>
          <ServiceNotes :device="device" />
        </div>

        <div v-if="deviceSupportsApp('yoyo')">
          <YoYoStats :device="device" />
          <YoYoLicenseStatus
            v-show="device.system !== null"
            :macaddress="device.system.macAddress"
          />
          <YoYoActions :device="device" v-if="isAdmin()" v-on:updateYoYo="updateYoYo($event)" />
        </div>
        <div v-if="deviceSupportsApp('myGarage') && device.stats.yamaha">
          <MyGarageStats :stats="device.stats.yamaha" />
        </div>
        <div v-if="deviceSupportsApp('miServer')">
          <MagicInfoServerStats :device="device" />
        </div>
        <div v-if="deviceSupportsApp('miPlayer')">
          <MagicPlayerStats
            :device="device"
            v-on:downloadMagicPlayerPackage="downloadMagicPlayerPackage"
            :magicPlayerEvent="magicPlayerEvent"
          />
        </div>
        <div v-if="device.system.os === 'magicinfo'">
          <MagicInfoDisplayStats :device="device" />
        </div>

        <div v-if="deviceSupportsApp('resticClient')">
          <ResticStats
            :online="device.online"
            :restic="device.restic"
            :deviceUnique="device.hwUnique"
            @resticBackup="resticBackup()"
          />
        </div>

        <div v-if="deviceSupportsApp('sslCertificate')">
          <SSLCertificateStats
            :online="device.online"
            :deviceUnique="device.hwUnique"
            @deploySSLCertificate="deploySSLCertificate()"
            @sslCertificateStatus="sslCertificateStatus()"
          />
        </div>

        <!-- Timer preview -->
        <div>
          <MagicinfoTimerGrid :device="device" />
        </div>
      </grid-col>
    </grid>
    <!-- MagicINFO timers -->
    <div v-if="showTimerWindow && device.magicinfo.timers">
      <MagicInfoDisplayTimers
        :device="device"
        :timers="device.magicinfo.timers"
        v-model="showTimerWindow"
      />
    </div>

    <!-- Migration scripts -->
    <div v-if="userCan('view', 'command')">
      <v-dialog />
      <card title="Migration Scripts">
        <Migration :device-id="deviceId" :device="device" />
      </card>
    </div>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import BaseComponent from '../Base.vue';
import TextProperty from '../Property/TextProperty.vue';
import Property from '../Util/Property.vue';
import YoYoLicenseStatus from '../YoYo/YoyoLicenseStatus.vue';
import YoYoStats from '../YoYo/yoyo-stats.vue';
import YoYoActions from '../YoYo/yoyo-actions.vue';
import Migration from '../Table/Migration.vue';
import MagicInfoServerStats from '../MagicInfoServer/MagicInfoServerStats.vue';
import MagicInfoDisplayStats from '../MagicInfoDisplay/MagicInfoServerStats.vue';
import MagicInfoDisplayTimers from '../MagicInfoDisplay/MagicInfoDisplayTimers.vue';
import MagicinfoTimerGrid from '../MagicInfoDisplay/MagicInfoTimerGrid.vue';
import MagicPlayerStats from '../MagicPlayer/MagicPlayerStats.vue';
import AlertStatus from './AlertStatus.vue';
import MyGarageStats from '../MyGarage/mygarage-stats.vue';
import NoMachineStatus from '../NoMachine/status.vue';
import ResticStats from '../Restic/Stats.vue';
import SSLCertificateStats from '../Certificate/Stats.vue';
import ServiceNotes from '../Service/Notes.vue';
import Stats from './Cards/Stats.vue';
import SystemInfo from './Cards/SystemInfo.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import ValueSwitch from '../Switch/ValueSwitch.vue';

export default {
  name: 'singleDevice',
  extends: BaseComponent,
  components: {
    Migration,
    TextProperty,
    Property,
    YoYoLicenseStatus,
    YoYoStats,
    YoYoActions,
    MagicPlayerStats,
    MagicInfoServerStats,
    MagicInfoDisplayTimers,
    MagicInfoDisplayStats,
    MagicinfoTimerGrid,
    MyGarageStats,
    NoMachineStatus,
    ServiceNotes,
    AlertStatus,
    Stats,
    SystemInfo,
    ResticStats,
    SSLCertificateStats,
    Card,
    Grid,
    GridCol,
    BaseButton,
    ValueSwitch,
  },
  props: ['deviceId', 'events'],
  computed: {
    ranNoMachineCommand() {
      return this.commandsDone.includes('5dc916b16d975f62d2b3625c');
    },
  },
  watch: {
    deviceId(newVal) {
      if (this.refreshTimeout !== null) {
        console.debug('Removed refresh timeout.');
        clearInterval(this.refreshTimeout);
      }

      this.magicPlayerEvent = null;
      this.showTimerWindow = false;

      this.getDevice(newVal).then(() => {
        this.setHistory();
        this.setRefreshMoment();
      });
    },
    events(event) {
      if (event.type === 'device') {
        this.checkAndUpdateDevice(event);
      } else if (event.type === 'vpn') {
        if (event.target === this.device.hwUnique) {
          if ((event.turnedOn && event.success) || (!event.turnedOn && !event.success)) {
            this.vpnIsOpen = true;
            this.vpn.vpnWillOpen = true;
            this.vpn.vpnStatus = 'Connected';
            this.vpn.ip = event.ip;
            this.device.vpn.ip = event.ip;
          } else {
            this.vpnIsOpen = false;
            this.vpn.vpnWillOpen = false;
            this.vpn.vpnStatus = 'Disconnected';
          }
        }
      } else if (event.type === 'noMachineStatus') {
        if (event.target === this.device.hwUnique) {
          if (event.active) {
            this.noMachineIsOpen = true;
            this.noMachine.willOpen = true;
            this.noMachine.status = 'Connected';
            this.device.nomachine.port = event.port;
          } else {
            this.noMachineIsOpen = false;
            this.noMachine.willOpen = false;
            this.noMachine.status = 'Disconnected';
          }

          if (!event.success) {
            this.$noty.warning(event.message);
          }
        }
      } else if (event.type === 'magicPlayerResponse' || event.type === 'magicPlayerStatus') {
        if (event.device === this.device.hwUnique) {
          this.magicPlayerEvent = event;
        }
      }
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      device: null,
      vpnIsOpen: false,
      commandsDone: [],
      refreshTimeout: null,
      vpn: {
        vpnWillOpen: false,
        vpnStatus: 'Disconnected',
        ip: '',
      },
      noMachineIsOpen: false,
      noMachine: {
        willOpen: false,
        status: 'Disconnected',
      },
      magicPlayerEvent: null,
      rmserverWindow: null,
      showTimerWindow: false,
    };
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    manageTimers() {
      this.showTimerWindow = true;
    },
    location() {
      return window.location;
    },
    deviceSupportsApp(property) {
      if (this.device?.config?.app[property]?.enabled) {
        return true;
      }
      return false;
    },
    deviceSupportsRemote(property) {
      if (this.device?.config?.remote[property]?.enabled) {
        return true;
      }
      return false;
    },

    setHistory() {
      let newtitle;
      if (this.device) {
        newtitle = `${this.$router.currentRoute.meta.title} - ${this.device.name}`;
      } else {
        newtitle = this.$router.currentRoute.meta.title;
      }
      document.title = newtitle;
      window.history.pushState(null, newtitle, window.location.href);
    },
    async checkAndUpdateDevice(device) {
      if (this.device.hwUnique === device.target) {
        await this.getDevice(this.device._id);
        this.setRefreshMoment();
      }
    },
    handleVpn() {
      if (!this.vpnIsOpen) {
        this.vpn.vpnWillOpen = true;
        this.$emit('vpnAction', {
          target: this.device._id,
          on: true,
        });
        this.vpn.vpnStatus = 'Trying to connect...';
      } else {
        this.vpn.vpnWillOpen = false;
        this.vpn.vpnStatus = 'Closing connection...';
        this.$emit('vpnAction', {
          target: this.device._id,
          on: false,
        });
      }
    },

    handleNoMachine() {
      if (!this.noMachineIsOpen) {
        this.noMachine.willOpen = true;
        this.$emit('noMachine', {
          target: this.device._id,
          on: true,
        });
        this.noMachine.status = 'Trying to connect...';
      } else {
        this.noMachine.willOpen = false;
        this.noMachine.status = 'Closing connection...';
        this.$emit('noMachine', {
          target: this.device._id,
          on: false,
        });
      }
    },
    capitalize(input) {
      if (input) {
        return input.charAt(0).toUpperCase() + input.slice(1);
      }
      return '';
    },
    downloadLogZipFromCDN(messageObj) {
      if (messageObj.device === this.device.hwUnique) {
        const now = new Date();
        const nowOnlyNumbers = now.toISOString().split('.')[0].replace(/[^0-9]/g, '');
        const logZipName = `wherever-log-${this.device.name}-${nowOnlyNumbers}.zip`;

        this.$utils.http().get(messageObj.downloadLocation, {
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', logZipName); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    handleLogZipRequest() {
      this.$emit('logZip', {
        target: this.device._id,
      });
    },
    openVNC() {
      window.open(
        `https://${this.device.organization.proxy}/${this.device.vpn.ip}/vnc.html?host=${this.device.organization.proxy}&path=websockify/${this.device.vpn.ip}&autoconnect=1&resize=scale&password=yme2019`,
        '_blank',
        'location=no,menubar=no,status=no,titlebar=no,toolbar=no,width=1280,height=720',
        false,
      );
    },
    openYoYo() {
      window.open(
        `https://${this.device.organization.proxy}/${this.device.vpn.ip}/`,
        '_blank',
        'location=no,menubar=no,status=no,titlebar=no,toolbar=no,width=1280,height=720',
        false,
      );
    },
    openTerminal() {
      if (this.device.vpn.ip) {
        window.open(
          `https://${this.device.organization.ssh}/ssh/host/${this.device.vpn.ip}`,
          '_blank',
          'location=no,menubar=no,status=no,titlebar=no,toolbar=no,width=1280,height=720',
          false,
        );
      }
    },
    changeNameHandler() {
      this.changeName = !this.changeName;
    },
    rebootDevice() {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you REALLY SURE you want reboot this device?')) {
        this.$emit('rebootDevice', {
          target: this.device._id,
        });
      }
    },
    async rebootDisplay() {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you REALLY SURE you want reboot this display?')) {
        const response = await Utils.fetch(
          '/api/v1/miservers/display/reboot',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              devices: [this.device._id],
            }),
          },
          this,
        ).then((res) => res.json());

        if (response.success && response.successDevices.length > 0) {
          this.$noty.success('Succesfully rebooted the device!');
        } else {
          this.$noty.warning('Could not reboot the device.');
        }
      }
    },
    downloadMagicPlayerPackage(type) {
      this.$emit('downloadMagicPlayerPackage', {
        target: this.device._id,
        downloadAction: type,
      });
    },
    resticBackup() {
      this.$emit('resticBackup', {
        target: this.device._id,
      });
    },
    restartWherever() {
      this.$emit('restartWherever', {
        target: this.device._id,
      });
    },
    updateWherever() {
      this.$emit('updateWherever', {
        target: this.device._id,
      });
    },
    updateYoYo(stable) {
      this.$emit('updateYoYo', {
        target: this.device._id,
        stable,
      });
    },
    deploySSLCertificate() {
      this.$emit('sslCertificateDeploy', {
        target: this.device._id,
      });
    },
    sslCertificateStatus() {
      this.$emit('sslCertificateStatus', {
        target: this.device._id,
      });
    },
    async getDevice(id) {
      const response = await Utils.fetch(`/api/v1/devices/${id}`, {}, this).then((res) =>
        res.json(),
      );
      this.vpnIsOpen = false;
      this.vpn = {
        vpnWillOpen: false,
        vpnStatus: 'Disconnected',
      };
      if (response.success) {
        this.ajaxCompleted = true;
        this.device = response.device;

        if (this.device.vpn.active) {
          this.vpnIsOpen = true;
          this.vpn = {
            vpnWillOpen: true,
            vpnStatus: 'Connected',
          };
        }

        if (this.device.nomachine && this.device.nomachine.active) {
          this.noMachineIsOpen = true;
          this.noMachine = {
            willOpen: true,
            status: 'Connected',
            port: this.device.nomachine.port,
          };
        } else {
          this.noMachineIsOpen = false;
          this.noMachine = {
            willOpen: false,
            status: 'Disconnected',
            port: null,
          };
        }

        /**
         * Create correct MagicInfo preview url, either thumbnail or capture or both
         */
        if (typeof this.device.magicinfo === 'object') {
          if (typeof this.device.magicinfo.stats.thumbUrl === 'string') {
            this.device.magicinfo.stats.previewThumb =
              this.device.magicinfo.miserver.thumbnail + this.device.magicinfo.stats.thumbUrl;
          } else {
            this.device.magicinfo.stats.previewThumb = false;
          }
          if (typeof this.device.magicinfo.miserver.capture === 'string') {
            this.device.magicinfo.stats.previewCapture = `${
              this.device.magicinfo.miserver.capture + this.device.magicinfo.system.deviceId
            }.jpg`;
          } else {
            this.device.magicinfo.stats.previewCapture = false;
          }
        }
        this.loadCommands();
      }
    },
    async loadCommands() {
      const response = await Utils.fetch(
        `/api/v1/devices/${this.device._id}/commands`,
        {},
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.commandsDone = response.commandsDone.filter((e) => e.success).map((e) => e.script._id);
      }
    },
    async setRefreshMoment() {
      if (this.device.online && this.device.stats && this.device.stats.updatedAt) {
        const timeout = 62000 - (Date.now() - this.device.stats.updatedAt);

        if (timeout > 0) {
          this.refreshTimeout = setTimeout(async () => {
            await this.getDevice(this.device._id);
            this.setRefreshMoment();
          }, timeout);
          // console.debug(`Will reload this device in ${timeout} ms.`);
        }
      }
    },
    async startRemoteManagement() {
      try {
        const response = await this.$utils
          .http()
          .get(
            `/api/v1/miservers/${this.device.magicinfo.miserver._id}/startrm/${this.device.magicinfo.system.deviceId}`,
          );
        this.rmserverWindow = window.open(
          response.data.url,
          'rmserverWindow',
          'menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=1380,height=890',
        );
        this.rmserverWindow.focus();

        this.pollTimer = setInterval(() => {
          if (this.rmserverWindow.closed) {
            clearInterval(this.pollTimer);
            this.$utils
              .http()
              .get(
                `/api/v1/miservers/${this.device.magicinfo.miserver._id}/stoprm/${this.device.magicinfo.system.deviceId}`,
              );
          }
        }, 1000);
      } catch (e) {
        this.$noty.warning(e.response.data.message);
      }
    },
    async setSourceMagicInfo() {
      // console.log(this.device);
      const response = await Utils.post(
        '/api/v1/miservers/set/source',
        {
          devices: [this.device._id],
          sourceId: 96,
        },
        this,
      ).then((res) => res.json());

      if (response.success) {
        this.$noty.success('Set source to MagicINFO');
      } else {
        this.$noty.warning('Error setting source');
      }
    },
    async setTimezoneMagicInfo() {
      // console.log(this.device);
      const response = await Utils.post(
        '/api/v1/miservers/set/timezone',
        {
          devices: [this.device._id],
          timeZoneIndex: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        },
        this,
      ).then((res) => res.json());
      console.log(response);
      if (response.success) {
        this.$noty.success(
          'Set timezone to "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"',
        );
      } else {
        this.$noty.warning('Error setting timezone');
      }
    },
    async downloadNoMachineConfig(url) {
      const urlParts = url.split('/');
      const name = urlParts[urlParts.length - 1];

      const response = await this.$utils.http().get(url);
      const fileBase64 = btoa(response.data);
      const fileUri = `data:application/octet-stream;charset=utf-8;base64,${fileBase64}`;

      const a = document.createElement('a');
      a.href = fileUri;
      a.setAttribute('download', name);
      a.click();
    },
  },
  mounted() {
    this.getDevice(this.deviceId).then(() => {
      this.setHistory();
    });

    this.$root.$on('reloadDevice', () => {
      this.getDevice(this.deviceId).then(() => {
        this.setHistory();
      });
    });

    this.$root.$on('downloadLogZip', (messageObj) => {
      this.downloadLogZipFromCDN(messageObj);
    },
    );
  },
  beforeDestroy() {
    if (this.refreshTimeout !== null) {
      console.debug('Removed refresh timeout.');
      clearInterval(this.refreshTimeout);
    }
  },
};
</script>

<style scoped lang="scss">
.card.notice {
  background: #916335;
  border: 1px solid #f8b36b;
  color: #fff;
  padding: 10px 10px;
  margin-bottom: 15px;
  border-radius: 2px;

  h4 {
    color: #fff;
  }
}

.sub-name {
  margin-top: -15px;
  font-weight: normal;
  font-size: 15px;
}

.actions {
  margin-bottom: 10px;
}

.loader {
  text-align: center;
  padding: 20px 0;

  img {
    height: 30px;
  }
}

.buildnumber {
  opacity: 0.3;
}

.switch {
  margin-top: 6px;
}

.nomachine-buttons a {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.3);
}

.grey {
  color: rgba(255, 255, 255, 0.3);
}

.grey:hover {
  color: rgba(255, 255, 255, 0.6);
}
</style>

<style lang="scss">
h1.title span.ti-tag {
  background-color: #f8b36b;
  color: #1e2a31;
  border-radius: 2px;
  display: inline-block;
  padding: 3px 5px;
  margin: 2px;
  font-size: 11px;
  top: -5px;
  position: relative;

  &:first-child {
    margin-left: 10px;
  }
}

.actions a {
  margin-left: 5px;
}

.spacing {
  padding-bottom: 15px;
}

.button {
  display: inline-block;
  margin: 2px;
}
</style>
