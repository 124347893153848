/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Configuration from '../../models/WhereverConfig';

interface ConfigState {
  config: Configuration | null;
}

const state = () => ({
  config: null,
});
const actions = {};

const getters = {
  config: (state: ConfigState) => state.config,
};

const mutations = {
  setConfig(state: ConfigState, config: Configuration | null) {
    state.config = config;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
